import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const Intro2 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(true);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div>
          {/*<img src={toAbsoluteUrl('/media/logos/note-web-1.jpg')} style={{ width: WIDTH }} alt='intro' />*/}
          <iframe
            style={{ width: '100%', height: '100vh' }}
            src="https://www.robotanan.com/webviewgioithieu"
            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
          />
        </div>


        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >

          <a href="https://www.robotanan.com/quatanganbi" target='_blank'>
            <button
              disabled={ isCorrect ? '' : 'disabled' }
              type="button"
              style={{
                display: 'none',
                width: '100%',
                padding: '15px',
                backgroundColor: isCorrect ? '#999' : '#999',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bolder',
                  color: '#fff',
                  letterSpacing: 2.0
                }}
              >
                CÔNG NGHỆ ANBI
              </span>
            </button>
          </a>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              history.push('/step-one')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              QUAY LẠI ĐĂNG KÝ
            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {Intro2}
