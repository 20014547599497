import React from 'react'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const NoteScan = () => {
  const history = useHistory()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
          backgroundImage: `url(${toAbsoluteUrl('/media/logos/scan-back.jpg')})`,
          backgroundSize: '100%'
        }}
      >
        <div>
          <img
            src={toAbsoluteUrl(LANGUAGE === 'VI' ? '/media/logos/note-scan.png' : '/media/logos/note-scane.png')}
            style={{
              width: WIDTH,
              padding: '140px 20px 20px 20px'
            }}
            alt='intro' />
        </div>
        <div
          style={{
            width: '100%',
            padding: '0px 20px 20px 20px'
          }}
        >
          <button
            type="button"
            style={{
              display: 'block',
              width: '100%',
              padding: '15px',
              backgroundColor: '#0bc732',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              history.push('/step-eight')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              {LANGUAGE === 'VI' ? 'BẮT ĐẦU' : 'START'}
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export {NoteScan}
